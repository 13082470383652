@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');

* {
  margin: 0;
  padding: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.selector {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

body{
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
    background: #1D1F21;
    color: #C5C8C6;
}

.h-whole{
    display: flex;
    flex-direction: column;
    font-family: 'poppins', sans-serif;
}

@media (max-width: 35em){
    body.blur{overflow:hidden;}
    body.blur .h-page > *{-webkit-filter:blur(5px) brightness(0.7);filter:blur(5px) brightness(0.7);-webkit-transition:var(--transition);transition:var(--transition);pointer-events:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}

    .h-navbar-content{
        z-index: 50;
        position: fixed;
        opacity: 95%;
        inset: 0 0 0 20%;
        background: #C5C8C6;
        color: #373B41;
        padding-top: 100px;
        padding-left: 45px;
        flex-direction: column;
        font-size: 35px;
        transform: translateX(100%);
        transition: transform 350ms ease-out;
    }
    .h-navbar-content[data-visible="true"]{
        transform: translateX(0);
    }
    .h-mobile-nav-toggle{
        position: absolute;
        aspect-ratio: 1;
        width: 3rem;
        color: #C5C8C6;
        top: 20px;
        right: 20px;
        z-index: 100;
        cursor: pointer;
        outline: none;
        -webkit-tap-highlight-color: transparent;

        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
    }
    .h-mobile-nav-toggle span{
        display: block;
        position: absolute;
        height: 7px;
        width: 80%;
        background: #C5C8C6;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-out;
        -moz-transition: .25s ease-out;
        -o-transition: .25s ease-out;
        transition: .25s ease-out;
    }

    .h-mobile-nav-toggle span:nth-child(1) {
      top: 0px;
    }

    .h-mobile-nav-toggle span:nth-child(2),.h-mobile-nav-toggle span:nth-child(3) {
      top: 15px;
    }

    .h-mobile-nav-toggle span:nth-child(4) {
      top: 30px;
    }

    .h-mobile-nav-toggle.open span:nth-child(1) {
      top: 18px;
      width: 0%;
      left: 50%;
    }

    .h-mobile-nav-toggle.open span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .h-mobile-nav-toggle.open span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    .h-mobile-nav-toggle.open span:nth-child(4) {
      top: 18px;
      width: 0%;
      left: 50%;
    }

    .h-mobile-nav-toggle.open span{
        background: #373B41;
    }
}

@media (min-width: 35em){
    .h-navbar-content{
        align-items: center;
        padding-right: 30px;
        font-size: 20px;
    }

    .h-mobile-nav-toggle{
        display: none;
    }
}

.h-navbar{
    padding: 1rem 1rem 1rem 0;
    display: flex;
    justify-content: space-between;
    height: 6vh;
    top: 0;
}

.h-navbar-logo{
    height: 6vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.h-navbar-image{
    height: 6vh;
    width: 70px;
}

.h-navbar-content{
    display: flex;
    gap: 30px;
}

.h-start-container{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap-reverse;
    width: 100%;
    height: 94vh;
}

.h-start{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.h-start-text{
    padding-left: 20px;
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
}

.h-start-head{
    padding-left: 20px;
    font-size: 55px;
    margin-top: 0;
    font-family: 'Roboto', sans-serif;
}

.h-subtitles{
    display: flex;
    margin-left: 40px;
    font-family: "Source Code Pro", monospaced;
}

.h-subtitles .h-static-txt{
    font-size: 40px;
}

.h-subtitles .h-dynamic-txts{
    margin-left: 15px;
    line-height: 53px;
    height: 53px;
    overflow: hidden;
}

.h-dynamic-txts li{
    list-style: none;
    color: #F0C674;
    font-size: 40px;
    top: 0;
    position: relative;
    animation: slide 12s steps(4) infinite;
}

@keyframes slide{
    100%{
        top: -212px;
    }
}

.h-dynamic-txts li{
    position: relative;
}

.h-dynamic-txts li::after{
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #1D1F21;
    border-left: 5px solid #F0C674;
    left: 0;
    animation: typing 3s steps(10) infinite;
}

@keyframes typing{
    40%,60%{
        left: calc(100%);
    }
    100%{
        left: 0;
    }
}

.h-contact-logos{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 15px;
}

.h-contact-logo{
    height: 50px;
    width: 50px;
    border-radius: 100px;
    transition: 500ms;
}

.h-contact-logo:hover{
    transform: rotate(-360deg);
}

.h-start-image{
    display: flex;
    justify-content: center;
    align-items: center;
}

.h-start-image img{
    height: 300px;
    width: 300px;
    border-radius: 50%;
}

#h-projects{
    background: #282A2E;
}

#h-skills{
    background: #373B41;
}

#h-experience{
    background: #282A2E;
}

#h-education{
    background: #1D1F21;
}

#h-contact{
    background: #282A2E;
}

.h-section{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
}

.h-section-head{
    text-align: center;
    font-size: 40px;
}

.h-project-holder{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-left: 10%;
    padding-right: 10%;
    gap: 30px;
}

.h-project-card{
    width: 270px;
    height: 125px;
    border: none;
    color: #C5C8C6;
    background-color: #5F819D;
    border-radius: 10px;
    transition: ease-out 300ms;
    font-size: 25px;box-shadow: inset 0 0 0 0 #81A2BE;
    outline: none;
}

.h-project-card:hover{
    box-shadow: inset 270px 0 0 0.09px #81A2BE;
    color: #282A2E;
    cursor: pointer;
}

.h-skills-holder{
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    justify-content: center;
    max-width: 1500px;
    gap: 50px;
    transition: 500ms;
}

.h-skill-card{
    width: 300px;
    height: 150px;
    background: #1D1F21;
    border-radius: 10px;
    font-size: 35px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    gap: 30px;
}

.h-experience-holder{
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-left: 25px;
}

.h-experience{
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 500px) {
  .h-experience {
    min-width: 522px;
  }
}
@media screen and (max-width: 500px) {
  .h-experience {
    min-width: 90vw;
  }
}

.h-experience-header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    gap: 10px;
    width: 200px;
    height: 125px;
    border-radius: 15px;
}

.h-experience-image-holder{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 150px;
    overflow: hidden;
}

.h-experience-image{
    height: 80px;
    cursor: pointer;
}

.h-experience-date{
    margin: 0;
    padding-top: 0;
    font-size: 18px;
    text-align: center;
}

.h-experience-description{
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin-top: 20px;
    padding-left: 20px;
    border-left: 2px solid #C5C8C6;
}

.h-experience-title::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #282A2E;
  border-radius: 999px;
  left: -29px;
  top: 7px;
  border: 3px solid #C5C8C6;
}

.h-experience-title{
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: bold;
    position: relative;
}

.h-experience-content{
    margin-left: 15px;
    margin-bottom: 10px;
    padding-right: 20px;
}