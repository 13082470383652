.cp-all{
  background: #FBFBFB;
  transition: 500ms;
  min-height: 100vh;
}

.cp-all-dark{
  background: #141414;
}

.cp-navbar{
  width: 100%;
  height: 80px;
  background: #F2F2F2;
  color: #373737;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  transition: 500ms;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cp-navbar-dark{
  background: #222222;
  color: #E4E3E2;
}

.cp-navbar-heading{
  margin-left: 30px;
  font-size: 25px;
}

.cp-color-mode{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  font-size: 30px;
  cursor: pointer;
}

.cp-add{
  margin-right: 40px;
}

.cp-add-button{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  background: #80AE35;
  color: #F2F2F2;
  width: 50px;
  border-radius: 0px 7px 7px 0px;
  cursor: pointer;
  transition: 500ms;
}

.cp-add-button-dark{
  background: #94CE9C;
  color: #222222;
}

.cp-add-button:hover{
  background: #42581A;
  font-size: 35px;
}

.cp-add-button-dark:hover{
  background: #C0DDC5;
}

.cp-add-input{
  outline: none;
  border: none;
  padding: 15px;
  width: 80px;
  font-size: 20px;
  border-radius: 7px 0px 0px 7px;
}

.cp-refresh{
  border-radius: 7px;
  display: flex;
  margin-right: 40px;
  justify-content: center;
  gap: 10px;
  align-items: center;
  font-size: 27px;
  height: 50px;
  width: 50px;
  color: #F2F2F2;
  cursor: pointer;
  background: #1E63B8;
  transition: 500ms;
  padding-bottom: 3px;
}

.cp-refresh-dark{
  background: #67A9ED;
  color: #222222;
}

.cp-refresh-icon{
  margin-top: 5px;
  transition: 500ms;
  color: #F2F2F2;
}

.cp-refresh-icon-dark{
  color: #222222;
}

.cp-refresh:hover{
  background: #013D63;
}

.cp-refresh-dark:hover{
  background: #8AB6E9;
}

.cp-refresh:hover .cp-refresh-icon{
  font-size: 35px;
  transform: rotate(360deg);
}

.cp-card-holder{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  padding-bottom: 90px;
}

.cp-grid-holder{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 50px;
  width: 80%;
}

.cp-grid-main{
  display: flex;
  justify-content: center;
}

.cp-grid-header{
  display: flex;
  background-color: #67A9ED;
  color: #EEEEEE;
  width: 100%;
  height: 60px;
  font-size: 25px;
  align-items: center;
}

.cp-grid-bar{
  display: flex;
  background-color: #EBEBEB;
  width: 100%;
  height: 45px;
  align-items: center;
  color: #444444;
  font-size: 20px;
}

.cp-number{
  width: 15%;
  text-align: center;
}
.cp-location{
  width: 15%;
  text-align: center;
}
.cp-division{
  width: 15%;
  text-align: center;
}
.cp-tier{
  width: 15%;
  text-align: center;
}
.cp-score{
  width: 10%;
  text-align: center;
}
.cp-rank{
  width: 15%;
  text-align: center;
}
.cp-state-rank{
  width: 15%;
  text-align: center;
}

.cp-holder{
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cp-display-toggle{
  width: 150px;
  height: 50px;
  background-color: #444444;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: 400ms;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cp-display-toggle:hover{
  background-color: #222222;
  color: #DDDDDD;
}

.cp-card{
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #F2F2F2;
  color: #373737;
  border-radius: 15px;
  padding: 30px;
  width: 400px;
  transition: 500ms;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cp-card-dark{
  background: #222222;
  color: #E4E3E2;
}

.cp-team-number{
  font-size: 25px;
  cursor: pointer;
}

.cp-team-classifiers{
  font-size: 20px;
  margin-bottom: 15px;
}

.cp-score-text{
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
}

.cp-sub-holder{
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.cp-sub-title{
  margin-top: 10px;
  font-size: 20px;
  margin-bottom: 5px;
}

.cp-delete{
  width: 115px;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;
  background-color: #333;
  margin-top: 15px;
  color: #ddd;
  transition: 500ms;
}

.cp-delete:hover{
  background-color: #000;
  color: #aaa;
}

.cp-chart-holder{
  width: 100%;
  background-color: #fbfbfb;
  display: flex;
  padding-top: 100px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}

#cp-alert{
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #FE2C32;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 30px;
}

#cp-alert.cp-show{
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {top: -10px; opacity: 0;}
  to {top: 30px; opacity: 1;}
}

@keyframes fadein {
  from {top: -10px; opacity: 0;}
  to {top: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {top: 30px; opacity: 1;}
  to {top: -10px; opacity: 0;}
}

@keyframes fadeout {
  from {top: 30px; opacity: 1;}
  to {top: -10px; opacity: 0;}
}